import React from 'react'
import { css } from 'theme-ui'
import rv from '@components/utils/buildResponsiveVariant'

const CardMediaImage = ({ variant, loading, image, title }) => (
  <amp-img 
    src={image.src}
    srcset={image.srcSet}
    layout="fill"
    alt={title}
    width={150}
    height={150}
    css={css({
      height: `full`,
      verticalAlign: `middle`, //avoid baseline gap
      img: {
        bg: `omegaLighter`
      },
      variant: rv(variant, 'image')
    })}
  />
)
CardMediaImage.defaultProps = {
  loading: 'lazy'
}

export default CardMediaImage
